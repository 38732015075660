import { useEffect, useState } from "react";
import { AnimatedCounter } from "../animate-counter";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@mindstonehq/ui";
import {
  usePointsBreakdownQuery,
  usePointsEarnedSubscription,
} from "~/graphql/__generated";
import PromptLevelChart from "../sandbox/prompt-level-chart";
import { useCurrentSpace, useUser } from "~/hooks/context-hook";
import { useRevalidator } from "react-router";
import { isMobile } from "~/utils/device-check";
import metrics from "~/utils/metrics";
import { useHoverPopover } from "~/hooks/useHoverPopover";

type PointsAndLevelDisplayProps = {
  variant: "mobile" | "desktop";
};

export function PointsAndLevelDisplay({ variant }: PointsAndLevelDisplayProps) {
  const isMobileDevice = isMobile();

  const user = useUser();
  const currentSpace = useCurrentSpace();
  const { revalidate } = useRevalidator();

  const [prevLevel, setPrevLevel] = useState(user?.level?.[0]?.level || 0);
  const [prevPoints, setPrevPoints] = useState(user?.evidencing.points || 0);

  const {
    isShown: showEPTooltip,
    handleOpen: handleEPTooltipOpen,
    handleClose: handleEPTooltipClose,
    handleOpenChange: handleEPTooltipOpenChange,
  } = useHoverPopover(300);

  const {
    isShown: showLevelTooltip,
    handleOpen: handleLevelTooltipOpen,
    handleClose: handleLevelTooltipClose,
    handleOpenChange: handleLevelTooltipOpenChange,
  } = useHoverPopover(300);

  const { data: breakdownData, loading: breakdownLoading } =
    usePointsBreakdownQuery({
      variables: {
        identifier: currentSpace.uniqueName,
      },
      skip: !showLevelTooltip,
    });

  const [currentPoints, setCurrentPoints] = useState(
    user?.evidencing.points || 0,
  );

  // event listener so we can refetch when level changes from the sandbox
  useEffect(() => {
    const handleLevelChange = () => {
      revalidate();
    };
    window.addEventListener("levelChange", handleLevelChange);
    return () => window.removeEventListener("levelChange", handleLevelChange);
  }, []);

  const promptLevel = user?.level?.[0]?.level ?? 0;
  const evidencePoints = user?.evidencing.points || 0;

  useEffect(() => {
    if (showEPTooltip) {
      metrics.track("ep_tooltip_shown");
    }
    if (showLevelTooltip) {
      metrics.track("level_tooltip_shown");
    }
  }, [showEPTooltip, showLevelTooltip]);

  // Subscribe to points earned in cohort
  usePointsEarnedSubscription({
    onData: (res) => {
      const pointsEarned = res.data.data?.pointsEarned;
      if (pointsEarned) {
        setPrevPoints(currentPoints);
        setCurrentPoints((prev) => prev + pointsEarned.updatedEvidencePoints);
      }
    },
  });

  useEffect(() => {
    if (promptLevel !== prevLevel) {
      setPrevLevel(promptLevel);
    }
    if (evidencePoints !== prevPoints) {
      setPrevPoints(evidencePoints);
      setCurrentPoints(evidencePoints);
    }
  }, [promptLevel, evidencePoints]);

  return (
    <div className="flex items-center space-x-4">
      <Popover open={showEPTooltip} onOpenChange={handleEPTooltipOpenChange}>
        <PopoverTrigger asChild>
          <div
            className="flex items-center space-x-1 cursor-pointer"
            onMouseEnter={handleEPTooltipOpen}
            onMouseLeave={handleEPTooltipClose}
          >
            <img
              src="/bolt-gradient.svg"
              className="h-5 w-5"
              alt="Evidence Points"
            />
            <span className="text-sm font-medium">
              <AnimatedCounter
                from={prevPoints}
                to={currentPoints}
                duration={1}
              />
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="bottom"
          align="end"
          className="bg-gradient-to-br from-blue-300 via-purple-300 to-purple-400 p-[2px]"
          onMouseEnter={handleEPTooltipOpen}
          onMouseLeave={handleEPTooltipClose}
        >
          <div className="flex items-start gap-3 p-4 max-w-[300px] bg-white dark:bg-card rounded-md">
            <div className="flex-1 space-y-2">
              <div className="flex items-center gap-2">
                <p className="font-bold text-foreground">
                  Your EP (Evidence Points)
                </p>
              </div>
              <p className="text-xs font-medium text-muted-foreground">
                Earn EP by completing trivia and practicing in the sandbox.
                Higher quality answers like detailed trivia or well-thought-out
                prompts earn more points, rewarding effort and accuracy.
              </p>
              <p className="text-xs font-medium text-muted-foreground">
                Learn more about how EP works{" "}
                <a
                  href="https://help.mindstone.com/en/articles/8121223-what-are-evidence-points"
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </p>
            </div>
          </div>
        </PopoverContent>
      </Popover>

      <Popover
        open={showLevelTooltip}
        onOpenChange={handleLevelTooltipOpenChange}
      >
        <PopoverTrigger asChild>
          <div
            className="flex items-center space-x-1 cursor-pointer"
            onMouseEnter={handleLevelTooltipOpen}
            onMouseLeave={handleLevelTooltipClose}
          >
            <img
              src="/chart-bar-gradient.svg"
              className="h-5 w-5"
              alt="Level"
            />
            <span className="text-sm font-medium">
              <AnimatedCounter from={prevLevel} to={promptLevel} duration={1} />
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent
          side="bottom"
          align="end"
          className="bg-gradient-to-br from-blue-300 via-purple-300 to-purple-400 p-[2px] w-[400px]"
          onMouseEnter={handleLevelTooltipOpen}
          onMouseLeave={handleLevelTooltipClose}
        >
          <div className="flex flex-col items-start gap-3 p-4 bg-white dark:bg-card rounded-md">
            <div className="flex-1 space-y-2">
              <div className="flex items-center gap-2">
                <p className="font-bold text-foreground">Your Prompt Level</p>
              </div>
              <p className="text-xs font-medium text-muted-foreground">
                Your prompt level is determined by the overall average score of
                all your prompts in the Sandbox. As you continue to practice,
                your level can increase or decrease based on the quality of your
                prompts.
              </p>
            </div>
            {breakdownLoading ? (
              <div className="w-full flex justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
              </div>
            ) : (
              breakdownData?.mindspace?.programs?.enrolled?.items?.[0]?.cohort
                ?.promptPerformance && (
                <div className="w-full">
                  <PromptLevelChart
                    data={
                      breakdownData.mindspace.programs.enrolled.items[0].cohort
                        .promptPerformance
                    }
                  />
                </div>
              )
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
