import { useCallback, useEffect, useRef, useState } from "react";
import { useFetcher } from "react-router";

export interface PaginatedData<T, TNext> {
  items: T[];
  hasMore: boolean;
  nextItem?: TNext | null;
}
export default function useInfiniteScroll<T, TNext>(
  initialData: PaginatedData<T, TNext>,
  fetcher: (nextItem: TNext) => Promise<PaginatedData<T, TNext>>,
) {
  const loading = useRef<boolean>(false);
  const [items, setItems] = useState(initialData.items);
  const [hasMore, setHasMore] = useState(initialData.hasMore);
  const [nextItem, setNextItem] = useState(initialData.nextItem);
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setItems(initialData.items);
    setNextItem(initialData.nextItem);
    setHasMore(initialData.hasMore);
  }, [initialData]);

  // Function to load more items
  const loadMoreItems = useCallback(async () => {
    if (
      loading.current ||
      !hasMore ||
      nextItem === undefined ||
      nextItem === null
    ) {
      return;
    }

    loading.current = true;
    const response = await fetcher(nextItem);
    setItems((prevItems) => [...prevItems, ...response.items]);
    setHasMore(response.hasMore);
    setNextItem(response.nextItem);
    loading.current = false;
  }, [loading, hasMore, nextItem]);

  // IntersectionObserver to trigger load more items
  useEffect(() => {
    if (!hasMore) return;
    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      });

      observer.observe(currentSentinel);
      return () => {
        observer.disconnect();
      };
    }
  }, [loadMoreItems, hasMore, nextItem, initialData]);

  return { items, loading, hasMore, sentinelRef, nextItem, setItems };
}

export function useFetcherInfiniteScroll<T, TNext>(
  initialData: PaginatedData<T, TNext>,
  remixFetcherURL: (nextItem: TNext) => string,
) {
  const loading = useRef<boolean>(false);
  const [items, setItems] = useState(initialData.items);
  const [hasMore, setHasMore] = useState(initialData.hasMore);
  const [nextItem, setNextItem] = useState(initialData.nextItem);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const fetcher = useFetcher<PaginatedData<T, TNext>>();

  useEffect(() => {
    setItems(initialData.items);
    setNextItem(initialData.nextItem);
    setHasMore(initialData.hasMore);
  }, [initialData]);
  // Function to load more items
  const loadMoreItems = useCallback(async () => {
    if (
      loading.current ||
      !hasMore ||
      nextItem === undefined ||
      nextItem === null
    ) {
      return;
    }

    loading.current = true;
    fetcher.load(remixFetcherURL(nextItem));
    loading.current = false;
  }, [loading, hasMore, nextItem]);

  useEffect(() => {
    const newData = fetcher.data;
    if (!newData) {
      return;
    }
    setItems((prev) => [...prev, ...(newData.items as T[])]);
    // sorry for the any
    setNextItem((newData as any).nextItem as TNext);
    setHasMore(newData.hasMore);
  }, [fetcher?.data]);

  // IntersectionObserver to trigger load more items
  useEffect(() => {
    if (!hasMore) return;
    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      });

      observer.observe(currentSentinel);
      return () => {
        observer.disconnect();
      };
    }
  }, [loadMoreItems, hasMore, nextItem, initialData]);

  return { items, loading, hasMore, sentinelRef, nextItem, setItems };
}
