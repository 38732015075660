import metrics from "../../utils/metrics";
import { Popover, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { classNames, isFrontend } from "~/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import {
  Notification,
  NotificationsDocument,
  useGetAnnouncementsQuery,
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadMutation,
  useNotificationAddedSubscription,
  useNotificationsCountQuery,
  useNotificationsLazyQuery,
  useNotificationsQuery,
  useReadAnnouncementsMutation,
} from "~/graphql/__generated";
import { Link } from "react-router";
import NotificationContent from "./notification-content";
import { useIsAuthenticated, useUser } from "~/hooks/context-hook";
import {
  Badge,
  Button,
  cn,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@mindstonehq/ui";
import useInfiniteScroll from "~/hooks/infinite-scroll";

export default function Notifications({
  container,
  iconClassName,
}: {
  container?: HTMLDivElement;
  iconClassName?: string;
}) {
  const isAuthenticated = useIsAuthenticated();
  const isClient = isFrontend();
  const { data, refetch } = useNotificationsCountQuery({
    skip: !isAuthenticated,
  });

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (isClient) {
      setUnreadCount(
        (data?.notifications?.unreadCount || 0) +
          (data?.getAnnouncements?.hasNew ? 1 : 0),
      );
    }
  }, [data, isClient]);

  useNotificationAddedSubscription({
    skip: !isAuthenticated,
    onData: (d) => {
      if (!d.data?.data?.notificationAdded) {
        return;
      }
      // add the new notification to the grouped notifications
      setUnreadCount((prev) => prev + 1);
    },
  });

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="sm" className={"relative"}>
          <span className="sr-only">Open notifications</span>
          <div className="relative">
            <BellIcon className={cn("h-5 w-5", iconClassName)} />
            {isClient && !!unreadCount && (
              <span className="absolute -top-0.5 -right-0.5 flex h-[6px] w-[6px] rounded-full bg-primary" />
            )}
          </div>
        </Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col h-full">
        <NotificationSheetContent updateCount={() => refetch()} />
      </SheetContent>
    </Sheet>
  );
}

function NotificationSheetContent({ updateCount }: { updateCount: () => any }) {
  const markedAsSeen = useRef<{ [key: string]: boolean }>({});
  const { data } = useNotificationsQuery({
    variables: {
      from: "",
    },
    fetchPolicy: "no-cache",
  });
  const [fetchNotifications] = useNotificationsLazyQuery({
    variables: {
      from: "",
    },
  });
  const [groupedNotifications, setGroupedNotifications] = useState(
    {} as {
      [key: string]: Notification[];
    },
  );
  const { data: announcements, refetch: refetchAnnouncements } =
    useGetAnnouncementsQuery();
  const [readAnnouncements] = useReadAnnouncementsMutation();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [markAllNotificationsAsRead] = useMarkAllNotificationAsReadMutation();
  const isAuthenticated = useIsAuthenticated();

  const [initialData, setInitialData] = useState({
    items: data?.notifications?.items || [],
    hasMore: !!data?.notifications?.nextItem,
    nextItem: data?.notifications?.nextItem || "",
  });
  useEffect(() => {
    if (!data) {
      return;
    }

    setInitialData({
      items: data?.notifications?.items || [],
      hasMore: !!data?.notifications?.nextItem,
      nextItem: data?.notifications?.nextItem || "",
    });
  }, [data]);

  const { items, hasMore, sentinelRef, nextItem, setItems } = useInfiniteScroll(
    initialData,
    (offset) =>
      fetchNotifications({
        variables: {
          from: offset,
        },
      }).then((res) => ({
        items: res?.data?.notifications?.items || [],
        hasMore: !!res?.data?.notifications?.nextItem,
        nextItem: res?.data?.notifications?.nextItem,
      })),
  );

  useNotificationAddedSubscription({
    skip: !isAuthenticated,
    onData: (d) => {
      if (d.data.error) {
        console.error("websocket error: ", d.data.error);
      }
      const updatedNotification = d.data.data?.notificationAdded;

      if (updatedNotification) {
        // add the new notification to the grouped notifications
        setItems((prev) => [updatedNotification, ...prev]);
      } else {
        console.error("evidencing error", d.data.error);
      }
    },
  });

  useEffect(() => {
    updateGroupedNotifications(items);
  }, [items]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.target) return;

          if (entry.isIntersecting) {
            const read = entry.target.getAttribute?.("data-read");
            if (read === "true") return;

            const id = entry.target.id;
            if (!id) return;
            handleMarkAsRead(id);
          }
        });
      },
      { threshold: 1.0 },
    );

    Object.values(groupedNotifications).forEach((group) => {
      group.forEach((notification) => {
        const element = document.getElementById(notification.id);
        if (element) {
          observer.observe(element);
        }
      });
    });

    return () => {
      observer.disconnect();
    };
  }, [groupedNotifications]);

  const handleMarkAsRead = (id: string) => {
    if (!markedAsSeen?.current || markedAsSeen?.current[id]) return;
    markedAsSeen.current[id] = true;
    markNotificationAsRead({
      variables: {
        id,
      },
    }).then(() => {
      updateCount();
    });
  };

  const handleMarkAllAsRead = () => {
    metrics.track("notification_item_mark_all_read");
    markAllNotificationsAsRead({
      refetchQueries: [NotificationsDocument],
    }).finally(() => {
      updateCount();
    });
  };

  const updateGroupedNotifications = (notifications: Notification[]) => {
    const groupedNotifications: {
      [key: string]: Notification[];
    } = {};
    const groups = {
      today: "Today",
      yesterday: "Yesterday",
      thisWeek: "This week",
      lastWeek: "Last week",
      older: "Older",
    };
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    const lastWeekStart = new Date();
    lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 7);
    const lastWeekEnd = new Date();
    lastWeekEnd.setDate(lastWeekEnd.getDate() - lastWeekEnd.getDay() - 1);

    notifications.forEach((notification) => {
      const date = new Date(notification.createdAt);
      if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
        if (!groupedNotifications[groups.today]) {
          groupedNotifications[groups.today] = [];
        }
        groupedNotifications[groups.today].push(notification);
        return;
      }
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        if (!groupedNotifications[groups.yesterday]) {
          groupedNotifications[groups.yesterday] = [];
        }
        groupedNotifications[groups.yesterday].push(notification);
        return;
      }
      if (date >= weekStart) {
        if (!groupedNotifications[groups.thisWeek]) {
          groupedNotifications[groups.thisWeek] = [];
        }
        groupedNotifications[groups.thisWeek].push(notification);
        return;
      }
      if (date >= lastWeekStart && date <= lastWeekEnd) {
        if (!groupedNotifications[groups.lastWeek]) {
          groupedNotifications[groups.lastWeek] = [];
        }
        groupedNotifications[groups.lastWeek].push(notification);
        return;
      }

      if (!groupedNotifications[groups.older]) {
        groupedNotifications[groups.older] = [];
      }
      groupedNotifications[groups.older].push(notification);
    });
    setGroupedNotifications(groupedNotifications);
  };

  return (
    <>
      <SheetHeader>
        <SheetTitle className="text-2xl">Notifications</SheetTitle>
        <SheetDescription>
          <button
            className="typography-body-md-medium link"
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </button>
        </SheetDescription>
      </SheetHeader>
      <div className="flex flex-1 flex-col overflow-y-auto p-6 -mr-6">
        <a
          href={
            announcements?.getAnnouncements?.url || "https://news.mindstone.com"
          }
          target="_blank"
          className="flex items-center pb-3 cursor-pointer"
          onClick={() => {
            fetch(`/internal/read-announcements`, {
              keepalive: true,
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
            });
          }}
        >
          <div
            className={classNames(
              "h-2 w-2 rounded-full",
              announcements?.getAnnouncements?.hasNew
                ? "bg-theme-text-error"
                : "",
            )}
          ></div>
          <div className="ml-2 flex flex-col flex-1 py-2">
            <div className="typography-body-md-bold">📢 Mindstone news</div>
            <div className="typography-body-sm-regular">
              New features, improvements and product updates
            </div>
          </div>
        </a>
        <div className="border-b border-theme-border-medium mx-6"></div>
        <div className="py-3 flex flex-col">
          {Object.keys(groupedNotifications).map((key, i) => {
            const notifications = groupedNotifications[key];
            return (
              <React.Fragment key={key}>
                <div className="flex flex-col py-3">
                  <div className="font-semibold">{key}</div>
                  <>
                    {notifications.map((notification) => (
                      <div key={notification.id}>
                        {notification.actionURL ? (
                          <Link
                            to={notification.actionURL}
                            className="cursor-pointer"
                          >
                            <NotificationContent notification={notification} />
                          </Link>
                        ) : (
                          <NotificationContent notification={notification} />
                        )}
                      </div>
                    ))}
                  </>
                </div>
                {i !== Object.keys(groupedNotifications).length - 1 && (
                  <div className="border-b border-theme-border-medium mx-12"></div>
                )}
              </React.Fragment>
            );
          })}
          <div ref={sentinelRef} className="h-1 pb-1" />
          {!Object.keys(groupedNotifications)?.length && (
            <div className="flex flex-col px-12 items-center pt-16">
              <div className="typography-body-lg-bold">All caught up!</div>
              <div className="typography-body-sm-regular">
                All your important notifications will appear here.
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
