import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@mindstonehq/ui";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { LinePath, AreaClosed } from "@visx/shape";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { curveMonotoneX, curveNatural } from "@visx/curve";

import React, { useEffect } from "react";
import { PromptPerformance } from "~/graphql/__generated";
import { isFrontend } from "~/utils";
import { Link } from "react-router";

interface BarData {
  date: string;
  me: number;
  cohort: number;
}

// Use hex color values
const primaryColor = "#8b5cf6";
const red400 = "#f87171";

const formatAxisDate = (value: { valueOf(): number }) =>
  `Week ${value.valueOf() + 1}`;

const formatData = (data: PromptPerformance): BarData[] => {
  const mineData = data.mine || [];
  const cohortData = data.cohort || [];

  return mineData.map((week, index) => ({
    date: week.date,
    me: week.value,
    cohort: cohortData[index]?.value || 0,
  }));
};

export default function PromptLevelChart({
  data: performanceData,
}: {
  data?: PromptPerformance | null;
}) {
  const frontend = isFrontend();
  const [width, setWidth] = React.useState(0);
  const chartMinHeight = 250;
  const [containerHeight, setContainerHeight] = React.useState(chartMinHeight);
  const chartHeight = containerHeight - 85;
  const chartContainerRef = React.useRef<HTMLDivElement | null>(null);
  const data = performanceData ? formatData(performanceData) : [];
  const margin = { top: 20, right: 20, bottom: 30, left: 20 };

  useEffect(() => {
    setWidth(calculateChartWidth());
    setContainerHeight(calculateChartHeight());
  }, []);

  const calculateChartWidth = () => {
    return chartContainerRef.current?.offsetWidth || 300;
  };

  const calculateChartHeight = () => {
    return Math.max(
      chartContainerRef.current?.offsetHeight || chartMinHeight,
      chartMinHeight,
    );
  };

  // scales
  const xScale = scaleLinear({
    domain: [0, data.length - 1],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear<number>({
    domain: [0, 5],
    range: [chartHeight - margin.bottom, margin.top],
  });

  const ordinalColorScale = scaleOrdinal({
    domain: ["You", "Your Group"],
    range: [primaryColor, red400],
  });

  return (
    <Card className={"flex-1 flex flex-col"}>
      <CardContent className="flex-1">
        {data.length ? (
          <>
            <div
              className="flex flex-col flex-1 h-full min-h-[inherit] w-full space-y-3"
              ref={chartContainerRef}
            >
              {frontend && (
                <>
                  <svg width={width} height={chartHeight}>
                    <defs>
                      <linearGradient
                        id="primaryGradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: primaryColor, stopOpacity: 0.2 }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: primaryColor, stopOpacity: 0.2 }}
                        />
                      </linearGradient>
                      <linearGradient
                        id="indigoGradient"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: red400, stopOpacity: 0.2 }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: red400, stopOpacity: 0.2 }}
                        />
                      </linearGradient>
                    </defs>
                    <GridRows
                      tickValues={[0, 1, 2, 3, 4, 5]}
                      left={margin.left}
                      lineStyle={{ strokeDasharray: "10 10" }}
                      className="stroke-muted-hover"
                      scale={yScale}
                      width={width - margin.left - margin.right}
                      top={0}
                    />

                    <Group>
                      {/* Area and Line for cohort data */}
                      <AreaClosed
                        data={data}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScale(d.cohort)}
                        yScale={yScale}
                        fill={`${red400}33`} // 33 is 20% opacity in hex
                        curve={curveMonotoneX}
                      />
                      <LinePath
                        data={data}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScale(d.cohort)}
                        stroke={red400}
                        strokeWidth={1}
                        curve={curveMonotoneX}
                      />

                      {/* Area and Line for user data */}
                      <AreaClosed
                        data={data}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScale(d.me)}
                        yScale={yScale}
                        fill={`${primaryColor}33`} // 33 is 20% opacity in hex
                        curve={curveMonotoneX}
                      />
                      <LinePath
                        data={data}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScale(d.me)}
                        stroke={primaryColor}
                        strokeWidth={1}
                        curve={curveMonotoneX}
                      />
                    </Group>

                    <AxisBottom
                      top={chartHeight - margin.bottom}
                      scale={xScale}
                      tickFormat={formatAxisDate}
                      hideTicks
                      hideAxisLine
                      numTicks={data.length}
                      tickComponent={(props) => {
                        const { fill, formattedValue, ...rest } = props;
                        return (
                          <text {...rest} className="fill-muted-foreground">
                            {formattedValue}
                          </text>
                        );
                      }}
                      tickLabelProps={{
                        fontSize: 11,
                        textAnchor: "middle",
                      }}
                    />
                    <AxisLeft
                      left={margin.left}
                      scale={yScale}
                      hideAxisLine
                      hideTicks
                      tickValues={[1, 2, 3, 4, 5]}
                      tickFormat={(l) => `${Number(l)}`}
                      tickComponent={(props) => {
                        const { fill, formattedValue, ...rest } = props;
                        return (
                          <text {...rest} className="fill-muted-foreground">
                            {formattedValue}
                          </text>
                        );
                      }}
                      tickLabelProps={{
                        fontSize: 11,
                        textAnchor: "middle",
                      }}
                    />
                  </svg>

                  <LegendOrdinal scale={ordinalColorScale}>
                    {(labels) => {
                      return (
                        <div className="flex justify-center !space-x-4 -translate-x-5">
                          {labels.map((label, i) => (
                            <LegendItem
                              key={`legend-${i}`}
                              className="!space-x-2"
                            >
                              <svg width={15} height={2}>
                                <rect
                                  fill={label.value}
                                  width={15}
                                  height={2}
                                />
                              </svg>
                              <LegendLabel className="font-medium text-xs align-center">
                                {label.text}
                              </LegendLabel>
                            </LegendItem>
                          ))}
                        </div>
                      );
                    }}
                  </LegendOrdinal>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="flex flex-col space-y-4 px-4 items-center justify-center h-full">
            <div>
              Go test out your skills in the Sandbox to start improving your
              prompting level.
            </div>
            <Link to="/sandbox">
              <Button>Go to Sandbox</Button>
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
