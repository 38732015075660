import { useRef, useState, useEffect } from "react";

/**
 * This code provides functionality for tooltips/popovers that are triggered by hover,
 * but should only close if the user clicks outside of the tooltip
 * This is useful for help popovers that work seamlessly on desktop/mobile with hover AND click behaviours.
 + * @param delay - The delay in milliseconds before closing the tooltip (default: 300)
 */
export function useHoverPopover(delay: number = 300) {
  const [isShown, setIsShown] = useState(false);
  const [isHoverTriggered, setIsHoverTriggered] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsHoverTriggered(true);
    setIsShown(true);
  };

  const handleClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsShown(false);
      setIsHoverTriggered(false);
    }, delay);
  };

  const handleOpenChange = (open: boolean) => {
    // Only allow closing via click if it wasn't triggered by hover
    if (!open && !isHoverTriggered) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { isShown, handleOpen, handleClose, handleOpenChange };
}
